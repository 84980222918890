import React from 'react';
import { graphql } from 'gatsby';
import { HelmetDatoCms } from 'gatsby-source-datocms';
import Layout from '../components/Layout';
import Banner from '../components/Banner';
import ModularBlocks from '../components/ModularBlocks';
import { buildUrl } from '../utils';

const DefaultPageTemplate = ({
  location: { pathname },
  data: {
    datoCmsPage: {
      seoMetaTags,
      title,
      bannerText,
      bannerImage,
      modularBlocks,
      subpages,
    },
  },
}) => (
  <Layout>
    <HelmetDatoCms seo={seoMetaTags} />
    <main>
      <Banner heading={title} text={bannerText} image={bannerImage} />
      <ModularBlocks
        items={modularBlocks}
        subpages={subpages.sort((a, b) => a.position - b.position)}
        pathname={buildUrl(pathname)}
      />
    </main>
  </Layout>
);

export const DefaultPageTemplateQuery = graphql`
  query DefaultPageTemplateQuery($id: String!) {
    datoCmsPage(id: { eq: $id }) {
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      title
      bannerText
      bannerImage {
        ...SubpageBannerImageFragment
      }
      modularBlocks {
        ...AccordionModularBlockFragment
        ...ContactFormModularBlockFragment
        ...ContainedImageModularBlockFragment
        ...ContentModularBlockFragment
        ...DownloadStripModularBlockFragment
        ...ExternalVideoModularBlockFragment
        ...FeaturedCranesModularBlockFragment
        ...FeaturedEquipmentCategoriesModularBlockFragment
        ...FeaturedEquipmentModularBlockFragment
        ...FeaturedLogosModularBlockFragment
        ...FeaturedProjectsModularBlockFragment
        ...FeaturedTeamModularBlockFragment
        ...ImageContentModularBlockFragment
        ...ImageSliderModularBlockFragment
        ...LatestNewsModularBlockFragment
        ...LatestPressReleasesModularBlockFragment
        ...PageLinkCardsModularBlockFragment
        ...ProjectTypeSelectorModularBlockFragment
        ...SubpageLinkCardsModularBlockFragment
        ...TabsModularBlockFragment
        ...TestimonialModularBlockFragment
        ...VideoContentModularBlockFragment
        ...VideoModularBlockFragment
      }
      subpages: treeChildren {
        ...PageLinkCardFragment
      }
    }
  }
`;

export default DefaultPageTemplate;
